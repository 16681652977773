import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

const Filter = props => {
  const { listItem, getItem, selectLetter } = props
  const [currentItem, setCurrentItem] = useState(props.currentItem)

  useEffect(() => {
    setCurrentItem(props.currentItem)
  }, [props.currentItem])

  const getCurrentItem = item => () => {
    setCurrentItem(item)
    selectLetter(item)
    if (getItem && typeof getItem === "function") {
      getItem(item)
    }
  }

  const renderItems = () => {
    if (!listItem) {
      return
    }
    const defaultListItem = ["**", ...listItem]

    return defaultListItem.map((item, index) => (
      <button
        className={`Filter-item ${currentItem === item ? "selected" : ""}`}
        key={`filter-${index}`}
        onClick={getCurrentItem(item)}
      >
        {item}
      </button>
    ))
  }

  return (
    <div className="Filter">
      <div className="Filter-wrapper">{renderItems()}</div>
    </div>
  )
}

Filter.propTypes = {
  listItem: PropTypes.array.isRequired,
  currentItem: PropTypes.number.isRequired,
  getItem: PropTypes.func,
}

export default Filter
