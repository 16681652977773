export const paragraphs = [
  `Son las entidades firmantes del convenio de cooperación corporativa con EFPA España por el que se comprometen a compartir los objetivos de EFPA España de autorregulación, de calidad en la actuación profesional y de comportamiento ético de sus profesionales.`,
  `Estas entidades firmantes del convenio de colaboración han iniciado un proceso de Certificación profesional EFPA de sus profesionales orientados a la asesoría financiera y a la planificación financiera personal, hasta alcanzar un porcentaje de sus profesionales certificados.`,
  `EFPA España se compromete a otorgar descuentos en los derechos de examen en función del número de candidatos presentados, a colaborar con la entidad para facilitar y agilizar los procesos de certificación de sus profesionales y a informar ampliamente de todas las actividades y servicios que la Asociación realice.`,
]

export const filterOption = [
  {
    label: "**",
    value: "**",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "B",
    value: "B",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "D",
    value: "D",
  },
  {
    label: "E",
    value: "E",
  },
  {
    label: "F",
    value: "F",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "H",
    value: "H",
  },
  {
    label: "I",
    value: "I",
  },
  {
    label: "J",
    value: "J",
  },
  {
    label: "K",
    value: "K",
  },
  {
    label: "L",
    value: "L",
  },
  {
    label: "M",
    value: "M",
  },
  {
    label: "N",
    value: "N",
  },
  {
    label: "O",
    value: "O",
  },
  {
    label: "P",
    value: "P",
  },
  {
    label: "Q",
    value: "Q",
  },
  {
    label: "R",
    value: "R",
  },
  {
    label: "S",
    value: "S",
  },
  {
    label: "T",
    value: "T",
  },
  {
    label: "U",
    value: "U",
  },
  {
    label: "V",
    value: "V",
  },
]

export const cardImages = [
  {
    image: "/imgs/efpa.jpg",
    link: "",
  },
  {
    image: "/imgs/efpa.jpg",
    link: "",
  },
  {
    image: "/imgs/efpa.jpg",
    link: "",
  },
  {
    image: "/imgs/efpa.jpg",
    link: "",
  },
]
