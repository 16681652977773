import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/Layout"
import TitleBlock from "../components/TitleBlock"
import Breadcrumb from "../components/Breadcrumb"
import Hero from "../components/Hero"
import { paragraphs } from "../data/entities"
import Filter from "../components/Filter"
import EntityCardImage from "../components/EntityCardImage"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"
import DownloadBtn from "../components/DownloadBtn"

export default function Entities({ location }) {
  const [letters, setLetters] = useState({})
  const [partners, setPartners] = useState()
  const [letterOption, setLetterOption] = useState("")

  const [{ data: dataLetters }] = useAxiosClient({
    url: `/enterprises/partners/letters/`,
    method: "GET",
  })

  useEffect(() => {
    if (dataLetters) {
      setLetters(dataLetters)
    }
  }, [dataLetters])

  const [{ data: dataPartners }] = useAxiosClient({
    url: `/enterprises/partners/`,
    method: "GET",
    params: {
      letter: letterOption === "**" ? null : letterOption,
    },
  })

  useEffect(() => {
    if (dataPartners && dataPartners.length) {
      setPartners(prev => {
        if (prev) getFilterOption()
        return dataPartners
      })
    }
  }, [dataPartners])

  const [optionChanged, setOptionChanged] = useState(false)

  const wrapperItems = useRef(null)

  useEffect(() => {
    const addAnimation = () => {
      if (!wrapperItems) return
      wrapperItems.current.classList.add("hidden")
    }

    if (optionChanged) {
      addAnimation()

      setTimeout(() => {
        setOptionChanged(false)
      }, 400)
    } else {
      wrapperItems.current.classList.remove("hidden")
    }
  }, [optionChanged])

  const getFilterOption = () => {
    setOptionChanged(true)
  }

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Quiénes somos - Entidades colaboradoras (Socios corporativos)" },
  ]

  const renderParagraphs = () => {
    return (
      paragraphs &&
      paragraphs.map(item => {
        return <p className="Entity-text">{item}</p>
      })
    )
  }

  const renderCards = () => {
    return (
      partners &&
      partners.map(item => (
        <EntityCardImage
          className="Entity-card"
          link={item?.www}
          image={item?.logo || "/imgs/default-image.jpg"}
          name={item?.name}
          isLink={true}
        />
      ))
    )
  }
  return (
    <Layout>
      <TitlePage
        title="Conoce las entidades colaboradoras de EFPA España"
        url={location.pathname}
      />
      <Hero image="/imgs/quienes-somos.jpg" text="Quiénes somos" />
      <div className="container950">
        <Breadcrumb path={path} />
        <div className="row efpa-row">
          <div className="col-12 Assosiation-col">
            <TitleBlock
              title="Entidades colaboradoras (Socios corporativos)"
              icon="fas fa-home"
            />
            <div className="efpa-content">
              <TitleBlock
                title="Entidades comprometidas con las cualificaciones y certificaciones de sus plantillas"
                icon="fas fa-layer-group"
                className="Entity-subtitle"
              />
              {renderParagraphs()}
              <DownloadBtn
                text="Descarga el Convenio A"
                link="/docs/EFPA-Convenio_Socio_Corporativo_entidades.pdf"
              />
            </div>
            <div className="efpa-content">
              <TitleBlock
                title="Entidades gestoras que contribuyen a la formación continua de los asociados de EFPA"
                icon="fas fa-layer-group"
                className="Entity-subtitle"
              />
              <p className="Entity-text">
                Entidades gestoras colaboradoras que contribuyen con EFPA en la
                formación continua de sus asociados ofreciendo seminarios,
                actividades y conferencias para facilitar la recertificación de
                los asociados.
              </p>
              <DownloadBtn
                text="Descarga el Convenio B"
                link="/docs/EFPA-Convenio_corporativo_socios_gestoras.pdf"
              />
            </div>
            <div className="efpa-content">
              <TitleBlock
                title="Entidades comprometidas con las certificaciones de EFPA España"
                icon="fas fa-plus"
                className="mb-1 border-0"
              />
              <Filter
                // getItem={getFilterOption}
                listItem={letters.letters}
                selectLetter={setLetterOption}
              />
              <div
                ref={wrapperItems}
                className={`Entity-items ${optionChanged ? "hide" : ""}`}
              >
                {renderCards()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
